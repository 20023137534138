import { FC } from 'react';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';

import { Gray_Light_White, web_max_width } from '@common/styles';

interface Props {
  support_dark_mode?: boolean;
}

export const Layout: FC<Props> = ({ support_dark_mode, children }) => {
  return (
    <>
      <Global styles={store_home_style} />
      <Wrap support_dark_mode={support_dark_mode}>{children}</Wrap>
    </>
  );
};

const Wrap = styled.main<{ support_dark_mode?: boolean }>`
  max-width: ${web_max_width}px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: ${({ support_dark_mode, theme }) =>
    support_dark_mode ? theme.colors.bg_surface : Gray_Light_White};
`;

const store_home_style = css`
  html {
    background: ${Gray_Light_White};
  }
  body {
    background: none;
  }
`;
