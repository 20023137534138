import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconArrowLeftBold32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={2} d='m20 7-9 9 9 9' />
    </Svg>
  );
};
export default SvgIconArrowLeftBold32;
