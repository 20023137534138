import { FC } from 'react';
import styled from '@emotion/styled';

import { ServerErrorIcon } from '@common/icons';
import { Gray_Light_400, Gray_Light_900, Typography } from '@common/styles';

export const ServerError: FC = () => {
  return (
    <Wrap>
      <ServerErrorIcon />
      <ErrorMessage className={Typography.BODY_18_BOLD}>문제가 생겼어요</ErrorMessage>
      <ErrorDescription className={Typography.BODY_15_MEDIUM}>
        잠시 후에 다시 시도해주세요.
        <br />
        문제가 지속되면 고객센터로 문의해주세요.
      </ErrorDescription>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const ErrorMessage = styled.h2`
  margin: 25px 0 0 0;
  color: ${Gray_Light_900};
`;

const ErrorDescription = styled.p`
  margin: 8px 0 0 0;
  color: ${Gray_Light_400};
  white-space: pre;
  text-align: center;
  line-height: 24px;
`;
