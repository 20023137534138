import {
  getBestProductCollectionList,
  getCartItemsCount,
  getComponentList,
  getSearchedStoreHomeProductList,
  getShopIdByMainDomain,
  getStoreInfo,
  getStoreInformation,
  getZigzinShippingComponentList,
} from '../graphql/index.2';
import {
  GetBestProductCollectionListVariables,
  GetComponentListVariables,
  GetSearchedStoreHomeProductListVariables,
  GetZigzinShippingComponentListVariables,
} from '../graphql/index_types.2';

export interface ErrorType {
  code: number;
  message: string;
}

const convertErrorObject = (error: any): ErrorType => {
  const { code, exception } = error;
  return { code: code || 500, message: exception?.message || '' };
};

export const fetchShopIdByMainDomain = async (main_domain: string, context?: any) => {
  try {
    const {
      data: {
        shop_ux_shop: { id },
      },
    } = await getShopIdByMainDomain({ main_domain }, { show_alert: false, context });

    return id;
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchStoreInfo = async (shop_id: string, context?: any) => {
  try {
    const {
      data: { shop_ux_shop, shop_ux_category_list },
    } = await getStoreInfo({ shop_id }, { show_alert: false, context });

    return { shop_ux_shop, shop_ux_category_list };
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchStoreInformation = async (shop_id: string, context?: any) => {
  try {
    const {
      data: { shop_ux_shop },
    } = await getStoreInformation({ shop_id }, { show_alert: false, context });

    return { shop_ux_shop };
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchComponentList = async (params: GetComponentListVariables, context?: any) => {
  try {
    const {
      data: { shop_ux_component_list },
    } = await getComponentList(params, { show_alert: false, context });

    return { shop_ux_component_list };
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchZigzinShippingComponentList = async (
  params: GetZigzinShippingComponentListVariables,
  context?: any,
) => {
  try {
    const {
      data: { zigzin_saved_shop_items },
    } = await getZigzinShippingComponentList(params, { show_alert: false, context });

    return zigzin_saved_shop_items;
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchSearchedStoreHomeProductList = async (params: GetSearchedStoreHomeProductListVariables) => {
  try {
    if (!params.goods_filter_option?.title) {
      return null;
    }

    const {
      data: { page_info: searched_product_list },
    } = await getSearchedStoreHomeProductList(params, { show_alert: false });
    return searched_product_list;
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchCartCount = async (context?: any) => {
  try {
    const {
      data: { cart_items_count },
    } = await getCartItemsCount(undefined, { show_alert: false, context });
    return cart_items_count;
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};

export const fetchGetBestProductList = async (input: GetBestProductCollectionListVariables) => {
  try {
    const { data } = await getBestProductCollectionList(input);
    return data.shop_ux_best_product_collection_list;
  } catch (error) {
    console.error(error);
    throw convertErrorObject(error);
  }
};
