import React from 'react';

export interface EnvConfigContextData {
  is_zigzag: boolean;
}

/**
 * 지그재그 앱 여부와 같은 환경 설정을 context로 관리할 수 있도록 합니다. SSR이 일어나는
 * 시점에서도 분기가 필요한 부분이 있기 때문에, isZigzag를 사용하면 hydration 오류가 발생하기
 * 때문입니다.
 *
 * 다만, store는 앱에서만 사용하고, website는 웹에서만 사용하는 것으로 고정되었기 때문에
 * 실제로 앱 여부는 검사하지 않고, pages 단에서 분기처리를 수행해서 context를 씌워주면 됩니다.
 */
export const EnvConfigContext = React.createContext<EnvConfigContextData>({
  is_zigzag: false,
});

export function wrapEnvConfigContext<P extends object>(
  data: EnvConfigContextData,
  Component: React.ComponentType<P>,
): React.ComponentType<P> {
  const new_component = (props: P) => {
    return (
      <EnvConfigContext.Provider value={data}>
        <Component {...props} />
      </EnvConfigContext.Provider>
    );
  };
  new_component.displayName = 'Wrapped' + (Component.displayName ?? Component.name);
  return new_component;
}
