import { FC } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { IllustWifi } from '@common/icons';
import { Gray_Light_050, Gray_Light_400, Gray_Light_900, Gray_Light_950, Typography } from '@common/styles';

export const NetworkOfflineError: FC = () => {
  const router = useRouter();

  return (
    <Wrap>
      <IllustWifi />
      <ErrorMessage className={Typography.BODY_18_BOLD}>네트워크에 연결하지 못했어요</ErrorMessage>
      <ErrorDescription className={Typography.BODY_15_MEDIUM}>
        네트워크 연결상태를 확인하고
        <br />
        다시 시도해주세요.
      </ErrorDescription>
      <RetryButton className={Typography.BODY_15_BOLD} onClick={() => router.reload()}>
        다시 시도하기
      </RetryButton>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const ErrorMessage = styled.h2`
  margin: 25px 0 0 0;
  color: ${Gray_Light_900};
`;

const ErrorDescription = styled.p`
  margin: 8px 0 0 0;
  color: ${Gray_Light_400};
  white-space: pre;
  text-align: center;
  line-height: 24px;
`;

const RetryButton = styled.button`
  margin: 16px 0 0 0;
  background: ${Gray_Light_950};
  border-radius: 24px;
  width: 130px;
  height: 48px;
  color: ${Gray_Light_050};
`;
