export const ServerErrorIcon = ({ width = 110, height = 94, ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} viewBox='0 0 110 94' fill='none' {...props}>
    <g clipPath='url(#clip0_14695_4776)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.8492 18.375H41.4163L44.0972 8.16726C46.9591 -2.7234 60.3063 -2.7234 63.1682 8.16726L65.8492 18.375Z'
        fill='#AAAAAA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.8489 18.375H41.416L44.097 8.16726C46.9589 -2.7234 60.3061 -2.7234 63.168 8.16726L65.8489 18.375Z'
        fill='url(#paint0_linear_14695_4776)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.1306 49.8897H33.135L37.2743 34.131H69.9913L74.1306 49.8897Z'
        fill='url(#paint1_linear_14695_4776)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.3523 81.1766H24.9146L28.9963 65.6483H78.2706L82.3523 81.1766Z'
        fill='#CCCCCC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.3523 81.1766H24.9146L28.9963 65.6483H78.2706L82.3523 81.1766Z'
        fill='url(#paint2_linear_14695_4776)'
      />
      <path
        d='M83.2132 80.9016H24.6558C19.1239 80.9016 14.6394 85.3861 14.6394 90.918H93.2296C93.2296 85.3861 88.7451 80.9016 83.2132 80.9016Z'
        fill='url(#paint3_linear_14695_4776)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.2747 34.1312H69.9917L65.8496 18.3752H41.4167L37.2747 34.1312Z'
        fill='#E7E7E7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.2749 34.1311H69.9919L65.8499 18.3751H41.417L37.2749 34.1311Z'
        fill='url(#paint4_linear_14695_4776)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.9958 65.6482H78.2701L74.1308 49.8895H33.1352L28.9958 65.6482Z'
        fill='#DDDDDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.9958 65.6482H78.2701L74.1308 49.8895H33.1352L28.9958 65.6482Z'
        fill='#DDDDDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.9958 65.6482H78.2701L74.1308 49.8895H33.1352L28.9958 65.6482Z'
        fill='url(#paint5_linear_14695_4776)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_14695_4776'
        x1='65.4916'
        y1='18.375'
        x2='53.6325'
        y2='18.375'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#AAAAAA' />
        <stop offset='1' stopColor='#CCCCCC' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_14695_4776'
        x1='73.9673'
        y1='49.8897'
        x2='45.0739'
        y2='49.8897'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#AAAAAA' />
        <stop offset='1' stopColor='#CCCCCC' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_14695_4776'
        x1='29.6639'
        y1='81.1766'
        x2='77.0492'
        y2='81.1766'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#CCCCCC' />
        <stop offset='1' stopColor='#AAAAAA' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_14695_4776'
        x1='53.9345'
        y1='80.9016'
        x2='53.9345'
        y2='90.918'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#CCCCCC' />
        <stop offset='1' stopColor='#AAAAAA' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_14695_4776'
        x1='65.3303'
        y1='34.1311'
        x2='53.9345'
        y2='34.1311'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#DDDDDD' />
        <stop offset='1' stopColor='#F9F9F9' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_14695_4776'
        x1='76.61'
        y1='65.6482'
        x2='44.65'
        y2='65.6482'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#DDDDDD' />
        <stop offset='1' stopColor='#F9F9F9' />
      </linearGradient>
      <clipPath id='clip0_14695_4776'>
        <rect width='109.41' height='94' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
