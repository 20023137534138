import { useEffect, useState } from 'react';

export const useNetworkStatus = () => {
  const [isOffline, setOffline] = useState(false);

  useEffect(() => {
    const offlineCallback = () => setOffline(true);
    const onlineCallback = () => setOffline(false);

    window.addEventListener('online', onlineCallback);
    window.addEventListener('offline', offlineCallback);

    return () => {
      window.removeEventListener('online', onlineCallback);
      window.removeEventListener('offline', offlineCallback);
    };
  }, [isOffline]);

  return { isOnlin: !isOffline, isOffline: isOffline };
};
