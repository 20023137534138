/* eslint-disable */
type CrTimestamp = number;
type CrJson = any;
type CrBigInt = number;
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShopIdByMainDomain
// ====================================================

export interface GetShopIdByMainDomain_shop_ux_shop {
  /**
   * 샵 아이디
   */
  id: string;
}

export interface GetShopIdByMainDomain {
  /**
   * 셀러 샵정보를 조회한다
   */
  shop_ux_shop: GetShopIdByMainDomain_shop_ux_shop;
}

export interface GetShopIdByMainDomainVariables {
  main_domain?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStoreInfo
// ====================================================

export interface GetStoreInfo_shop_ux_shop_style_list {
  /**
   * 값
   */
  value: string | null;
}

export interface GetStoreInfo_shop_ux_shop_representative_coupon {
  /**
   * 이름
   */
  title: string;
  /**
   * 쿠폰 전체 목록 URL
   */
  link_url: string;
}

export interface GetStoreInfo_shop_ux_shop_banner_group_banner_list_link_button {
  type: ShopUxComponentType;
  /**
   * 텍스트
   */
  text: string;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetStoreInfo_shop_ux_shop_banner_group_banner_list {
  id: string;
  /**
   * webp image url
   */
  image_url: string | null;
  /**
   * 링크 종류
   */
  link_type: ShopUxBannerLinkType | null;
  /**
   * 배너 링크 이동 버튼 컴포넌트
   */
  link_button: GetStoreInfo_shop_ux_shop_banner_group_banner_list_link_button | null;
  /**
   * 배너 타이틀
   */
  title: string | null;
  /**
   * 관리를 위한 배너이름
   */
  name: string | null;
}

export interface GetStoreInfo_shop_ux_shop_banner_group {
  /**
   * 오토스크롤 간격(초)
   */
  auto_rolling_interval: number | null;
  /**
   * 오토스크롤 여부
   */
  is_auto_rolling: boolean;
  banner_list: GetStoreInfo_shop_ux_shop_banner_group_banner_list[] | null;
}

export interface GetStoreInfo_shop_ux_shop {
  /**
   * 샵 아이디
   */
  id: string;
  /**
   * 샵 이름
   */
  name: string;
  /**
   * 샵 이미지
   */
  typical_image_url: string;
  /**
   * 연령대 목록
   */
  age_list: string[];
  /**
   * 스타일 목록
   */
  style_list: GetStoreInfo_shop_ux_shop_style_list[];
  /**
   * 한줄문구
   */
  comment: string | null;
  /**
   * 샵 대표 쿠폰
   */
  representative_coupon: GetStoreInfo_shop_ux_shop_representative_coupon | null;
  /**
   * 즐겨찾기 수
   */
  bookmark_count: number;
  /**
   * 브랜드홈 brand_id
   */
  brand_home_brand_id: string | null;
  /**
   * 상단 검색 버튼 및 검색관련 컴포넌트 노출 여부
   */
  show_search_button: boolean;
  /**
   * 배너
   */
  banner_group: GetStoreInfo_shop_ux_shop_banner_group | null;
}

export interface GetStoreInfo_shop_ux_category_list {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
}

export interface GetStoreInfo {
  /**
   * 셀러 샵정보를 조회한다
   */
  shop_ux_shop: GetStoreInfo_shop_ux_shop;
  /**
   * 셀러 카테고리를 목록을 조회한다
   */
  shop_ux_category_list: GetStoreInfo_shop_ux_category_list[];
}

export interface GetStoreInfoVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStoreInformation
// ====================================================

export interface GetStoreInformation_shop_ux_shop_style_list {
  /**
   * 값
   */
  value: string | null;
}

export interface GetStoreInformation_shop_ux_shop_sns_list_item_list {
  name: string;
  image_url: string;
  link_url: string;
}

export interface GetStoreInformation_shop_ux_shop_sns_list {
  item_list: GetStoreInformation_shop_ux_shop_sns_list_item_list[];
}

export interface GetStoreInformation_shop_ux_shop_model_list_item_list {
  /**
   * 식별자
   */
  id: string;
  /**
   * 모델 대표 이미지
   */
  representative_image: string | null;
  /**
   * 모델 이름
   */
  name: string;
  /**
   * 키
   */
  height: number;
  /**
   * 몸무게
   */
  weight: string;
  /**
   * 상의 사이즈
   */
  top: string | null;
  /**
   * 하의 사이즈
   */
  bottom: string | null;
  /**
   * 발 사이즈
   */
  foot: string | null;
  /**
   * 가슴 사이즈
   */
  bust: string | null;
  /**
   * 허리 사이즈
   */
  waist: string | null;
  /**
   * 힙 사이즈
   */
  hip: string | null;
}

export interface GetStoreInformation_shop_ux_shop_model_list {
  total_count: number;
  item_list: GetStoreInformation_shop_ux_shop_model_list_item_list[];
}

export interface GetStoreInformation_shop_ux_shop_title {
  text: string;
}

export interface GetStoreInformation_shop_ux_shop_subtitle {
  text: string;
}

export interface GetStoreInformation_shop_ux_shop {
  /**
   * 샵 아이디
   */
  id: string;
  /**
   * 샵 이름
   */
  name: string;
  /**
   * 샵 메인도메인
   */
  main_domain: string;
  /**
   * 한줄문구
   */
  comment: string | null;
  /**
   * 샵 도메인
   */
  url: string;
  /**
   * 스토어 바로가기 여부
   */
  has_store_shortcut: boolean;
  /**
   * 스타일 목록
   */
  style_list: GetStoreInformation_shop_ux_shop_style_list[];
  /**
   * 스토어 SNS 계정 목록
   */
  sns_list: GetStoreInformation_shop_ux_shop_sns_list;
  /**
   * 스토어 모델 목록
   */
  model_list: GetStoreInformation_shop_ux_shop_model_list;
  /**
   * 셀렉티드 스토어홈 변경사항 (NORMAL/ SELECTED)
   */
  store_home_theme: string | null;
  /**
   * 즐겨찾기 수
   */
  bookmark_count: number;
  /**
   * 즐찾 여부
   */
  is_saved_shop: boolean;
  /**
   * 즐겨찾기 수 노출 여부
   */
  is_bookmark_count_displayed: boolean | null;
  /**
   * 셀렉티드 스토어홈 한글 이름
   */
  title: GetStoreInformation_shop_ux_shop_title;
  subtitle: GetStoreInformation_shop_ux_shop_subtitle;
}

export interface GetStoreInformation {
  /**
   * 셀러 샵정보를 조회한다
   */
  shop_ux_shop: GetStoreInformation_shop_ux_shop;
}

export interface GetStoreInformationVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCategoryList
// ====================================================

export interface GetCategoryList_shop_ux_category_list_children {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
}

export interface GetCategoryList_shop_ux_category_list {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 자식 카테고리 목록
   */
  children: GetCategoryList_shop_ux_category_list_children[];
}

export interface GetCategoryList {
  /**
   * 셀러 카테고리를 목록을 조회한다
   */
  shop_ux_category_list: GetCategoryList_shop_ux_category_list[];
}

export interface GetCategoryListVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetComponentList
// ====================================================

export interface GetComponentList_shop_ux_component_list_category_list {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxText {
  type: ShopUxComponentType;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxLookBook_action_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 버튼 스타일
   */
  style: ShopUxButtonStyle | null;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxLookBook {
  type: ShopUxComponentType;
  /**
   * 타이틀
   */
  title: string;
  /**
   * 이미지 url 목록
   */
  image_url_list: string[];
  /**
   * 더보기 버튼
   */
  action_button: GetComponentList_shop_ux_component_list_item_list_ShopUxLookBook_action_button | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_brand_name_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_brand_name_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list {
  product: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_fomo | null;
  managed_category_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_action_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 버튼 스타일
   */
  style: ShopUxButtonStyle | null;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList {
  type: ShopUxComponentType;
  /**
   * 타이틀
   */
  title: string;
  /**
   * 이미지 url
   */
  image_url: string;
  /**
   * 상품 목록(최대 3개)
   */
  product_list: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_product_list[];
  /**
   * 더보기 버튼
   */
  action_button: GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList_action_button | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_brand_name_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_brand_name_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list {
  product: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_fomo | null;
  managed_category_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 버튼 스타일
   */
  style: ShopUxButtonStyle | null;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel {
  type: ShopUxComponentType;
  /**
   * 화면에 노출되는 아이템 개수
   */
  item_column_count: number;
  component_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_component_list[];
  /**
   * 더보기 버튼
   */
  more_button: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_more_button | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_main_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_sub_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_check_button_item_list {
  id: string;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_sorting_item_list {
  id: string;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting {
  type: ShopUxComponentType;
  main_title: GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_main_title | null;
  sub_title: GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_sub_title | null;
  check_button_item_list: GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_check_button_item_list[];
  sorting_item_list: GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting_sorting_item_list[];
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_main_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_sub_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_image {
  image_url: string;
  aspect_Ratio: number | null;
  /**
   * 이미지 클릭시 랜딩될 딥링크
   */
  link_url: string | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_brand_name_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_brand_name_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list {
  product: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_fomo | null;
  managed_category_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 버튼 스타일
   */
  style: ShopUxButtonStyle | null;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel {
  /**
   * 화면에 노출되는 아이템 개수
   */
  item_column_count: number;
  component_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_component_list[];
  /**
   * 더보기 버튼
   */
  more_button: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel_more_button | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 버튼 스타일
   */
  style: ShopUxButtonStyle | null;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup {
  type: ShopUxComponentType;
  /**
   * ProductGroup 의 product_carousel 을 구성하고있는 상품 타입.
   * - BEST_PRODUCTS: 판매베스트 상품
   * - ZIGZIN_PRODUCTS: 직진배송 상품
   * - COLLECTION: 스토어홈 컬랙션 상품
   */
  contents_type: string;
  main_title: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_main_title | null;
  sub_title: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_sub_title | null;
  image: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_image | null;
  product_carousel: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_product_carousel;
  /**
   * 더보기 버튼
   */
  more_button: GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup_more_button | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_brand_name_badge_list_image_size | null;
  small_image_size: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_brand_name_badge_list_small_image_size | null;
}

export interface GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem {
  type: ShopUxComponentType;
  product: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_fomo | null;
  managed_category_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export type GetComponentList_shop_ux_component_list_item_list = GetComponentList_shop_ux_component_list_item_list_ShopUxText | GetComponentList_shop_ux_component_list_item_list_ShopUxLookBook | GetComponentList_shop_ux_component_list_item_list_ShopUxImageBannerAndList | GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel | GetComponentList_shop_ux_component_list_item_list_ShopUxTextAndSorting | GetComponentList_shop_ux_component_list_item_list_ShopUxProductGroup | GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem;

export interface GetComponentList_shop_ux_component_list {
  /**
   * 조회된 마지막 상품의 ID
   */
  after_id: string | null;
  /**
   * 페이지네이션 가능 여부
   */
  has_next_page: boolean | null;
  /**
   * 현재 카테고리 목록
   */
  category_list: GetComponentList_shop_ux_component_list_category_list[] | null;
  /**
   * 컴포넌트 리스트
   */
  item_list: (GetComponentList_shop_ux_component_list_item_list | null)[];
}

export interface GetComponentList {
  /**
   * 셀러홈 컴포넌트 목록을 조회한다
   */
  shop_ux_component_list: GetComponentList_shop_ux_component_list | null;
}

export interface GetComponentListVariables {
  shop_id: string;
  category_id?: string | null;
  after_id?: string | null;
  sorting_item_id?: string | null;
  check_button_item_ids?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetZigzinShippingComponentList
// ====================================================

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxImageBannerGroup {
  type: UxComponentType;
  position: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxCategory_filter_item_list {
  str_id: string;
  name: string | null;
  selected: boolean | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxCategory {
  type: UxComponentType;
  position: number;
  filter_item_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxCategory_filter_item_list[];
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting_main_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting_sub_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting_sorting_item_list {
  id: string;
  name: string;
  selected: boolean;
  description: string | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting {
  type: UxComponentType;
  position: number;
  main_title: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting_main_title | null;
  sub_title: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting_sub_title | null;
  sorting_item_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting_sorting_item_list[];
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_shop {
  id: string;
  name: string;
  main_domain: string;
  state: number;
  zpay_status: number | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_discount_info {
  image_url: string | null;
  color: string | null;
  title: string | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 키
   */
  key: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_badge_list_image_size | null;
  small_image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_badge_list_small_image_size | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_brand_name_badge_list_image_size | null;
  small_image_size: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_brand_name_badge_list_small_image_size | null;
}

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem {
  type: UxComponentType;
  position: number;
  goods_id: string | null;
  image_url: string | null;
  webp_image_url: string | null;
  video_url: string | null;
  uuid: string | null;
  bridge: boolean | null;
  product_url: string | null;
  title: string | null;
  price: number | null;
  discount_rate: number | null;
  free_shipping: boolean | null;
  origin_id: string | null;
  zpay: boolean | null;
  column_count: number | null;
  ranking: number | null;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  catalog_product_id: string | null;
  browsing_type: UxBrowsingType;
  aid: string | null;
  image_ratio: number | null;
  review_count: number | null;
  shop_product_no: string | null;
  shop_id: string;
  shop_name: string | null;
  zpay_price: number | null;
  similar_search: boolean | null;
  is_zonly: boolean;
  fomo: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_fomo | null;
  shop: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_shop | null;
  discount_info: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_discount_info | null;
  /**
   * 북마크 여부
   */
  bookmarked: boolean;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_managed_category_list[] | null;
  badge_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list[] | null;
  /**
   * 가로형 상품카드 메타데이터 영역용 엠블렘
   */
  metadata_emblem_badge_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list[] | null;
  brand_name_badge_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem_brand_name_badge_list[] | null;
  /**
   * 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함)
   */
  is_plp_v2: boolean | null;
}

export type GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list = GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxImageBannerGroup | GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxCategory | GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxTextAndSorting | GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list_UxGoodsCardItem;

export interface GetZigzinShippingComponentList_zigzin_saved_shop_items {
  has_next: boolean | null;
  end_cursor: string | null;
  ui_item_list: GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list[];
}

export interface GetZigzinShippingComponentList {
  /**
   * 직진배송 즐겨찾기 한 쇼핑몰 상품 페이지
   */
  zigzin_saved_shop_items: GetZigzinShippingComponentList_zigzin_saved_shop_items;
}

export interface GetZigzinShippingComponentListVariables {
  shop_id: string;
  after?: string | null;
  sorting_id?: string | null;
  category_id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchedStoreHomeProductList
// ====================================================

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxImageBannerGroup {
  type: UxComponentType;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_shop {
  id: string;
  name: string;
  main_domain: string;
  state: number;
  zpay_status: number | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_discount_info {
  image_url: string | null;
  color: string | null;
  title: string | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 키
   */
  key: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_badge_list_image_size | null;
  small_image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_badge_list_small_image_size | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_brand_name_badge_list_image_size | null;
  small_image_size: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_brand_name_badge_list_small_image_size | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem {
  type: UxComponentType;
  goods_id: string | null;
  image_url: string | null;
  webp_image_url: string | null;
  video_url: string | null;
  uuid: string | null;
  bridge: boolean | null;
  product_url: string | null;
  title: string | null;
  price: number | null;
  discount_rate: number | null;
  free_shipping: boolean | null;
  origin_id: string | null;
  zpay: boolean | null;
  column_count: number | null;
  ranking: number | null;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  catalog_product_id: string | null;
  browsing_type: UxBrowsingType;
  aid: string | null;
  image_ratio: number | null;
  review_count: number | null;
  shop_product_no: string | null;
  shop_id: string;
  shop_name: string | null;
  zpay_price: number | null;
  similar_search: boolean | null;
  is_zonly: boolean;
  fomo: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_fomo | null;
  shop: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_shop | null;
  discount_info: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_discount_info | null;
  /**
   * 북마크 여부
   */
  bookmarked: boolean;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_managed_category_list[] | null;
  badge_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_thumbnail_emblem_badge_list[] | null;
  /**
   * 가로형 상품카드 메타데이터 영역용 엠블렘
   */
  metadata_emblem_badge_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_metadata_emblem_badge_list[] | null;
  brand_name_badge_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem_brand_name_badge_list[] | null;
  /**
   * 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함)
   */
  is_plp_v2: boolean | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxCheckButtonAndSorting_check_button_item_list {
  id: number | null;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxCheckButtonAndSorting_sorting_item_list {
  id: number | null;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetSearchedStoreHomeProductList_page_info_ui_item_list_UxCheckButtonAndSorting {
  type: UxComponentType;
  check_button_item_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxCheckButtonAndSorting_check_button_item_list[];
  sorting_item_list: GetSearchedStoreHomeProductList_page_info_ui_item_list_UxCheckButtonAndSorting_sorting_item_list[];
}

export type GetSearchedStoreHomeProductList_page_info_ui_item_list = GetSearchedStoreHomeProductList_page_info_ui_item_list_UxImageBannerGroup | GetSearchedStoreHomeProductList_page_info_ui_item_list_UxGoodsCardItem | GetSearchedStoreHomeProductList_page_info_ui_item_list_UxCheckButtonAndSorting;

export interface GetSearchedStoreHomeProductList_page_info {
  has_next: boolean | null;
  end_cursor: string | null;
  ui_item_list: GetSearchedStoreHomeProductList_page_info_ui_item_list[];
}

export interface GetSearchedStoreHomeProductList {
  page_info: GetSearchedStoreHomeProductList_page_info | null;
}

export interface GetSearchedStoreHomeProductListVariables {
  page_id: string;
  after?: string | null;
  filter_id_list?: string[] | null;
  goods_filter_option?: GoodsFilterOptionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCartItemsCount
// ====================================================

export interface GetCartItemsCount {
  /**
   * 장바구니 아이템 수
   */
  cart_items_count: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBestProductCollectionList
// ====================================================

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_category_list_children {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 부모 id
   */
  parent_id: string | null;
  /**
   * 동일 depth에서의 순서
   */
  sibling_order: number;
  /**
   * 카테고리 선택유무
   */
  selected: boolean | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_category_list {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 부모 id
   */
  parent_id: string | null;
  /**
   * 동일 depth에서의 순서
   */
  sibling_order: number;
  /**
   * 자식 카테고리 목록
   */
  children: GetBestProductCollectionList_shop_ux_best_product_collection_list_category_list_children[];
  /**
   * 카테고리 선택유무
   */
  selected: boolean | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_badge_list_image_size | null;
  small_image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_badge_list_small_image_size | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_brand_name_badge_list_image_size | null;
  small_image_size: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_brand_name_badge_list_small_image_size | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list {
  product: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_fomo | null;
  managed_category_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_main_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_sub_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_check_button_item_list {
  id: string;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_chip_tab_item_list {
  id: string;
  name: string | null;
  selected: boolean;
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab {
  type: ShopUxComponentType;
  main_title: GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_main_title | null;
  sub_title: GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_sub_title | null;
  check_button_item_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_check_button_item_list[];
  chip_tab_item_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab_chip_tab_item_list[];
}

export interface GetBestProductCollectionList_shop_ux_best_product_collection_list {
  /**
   * 웹뷰 노출 타이틀
   */
  title: string;
  /**
   * 전체 카테고리 목록
   */
  category_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_category_list[];
  /**
   * 베스트 상품 목록
   */
  item_list: GetBestProductCollectionList_shop_ux_best_product_collection_list_item_list[];
  /**
   * chip_tab_item_list : 상품 조회 기준 필터 리스트 (실시간, 일간, 주간, 월간)
   * check_button_item_list : 상품 필터 리스트 (직진 배송, 무료 배송)
   */
  filter_and_chip_tab: GetBestProductCollectionList_shop_ux_best_product_collection_list_filter_and_chip_tab | null;
  /**
   * 랭킹 업데이트 일시 (epoch time, UTC)
   */
  ranking_updated: string;
  store_home_theme: string | null;
}

export interface GetBestProductCollectionList {
  /**
   * 베스트 상품 모아보기 상세 페이지에 보여줄 카테고리와 상품 목록을 조회한다
   */
  shop_ux_best_product_collection_list: GetBestProductCollectionList_shop_ux_best_product_collection_list;
}

export interface GetBestProductCollectionListVariables {
  input: ShopUxBestProductCollectionListInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSelfProductionProductCollectionList
// ====================================================

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_category_list_children {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 부모 id
   */
  parent_id: string | null;
  /**
   * 동일 depth에서의 순서
   */
  sibling_order: number;
  /**
   * 카테고리 선택유무
   */
  selected: boolean | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_category_list {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 부모 id
   */
  parent_id: string | null;
  /**
   * 동일 depth에서의 순서
   */
  sibling_order: number;
  /**
   * 자식 카테고리 목록
   */
  children: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_category_list_children[];
  /**
   * 카테고리 선택유무
   */
  selected: boolean | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_fomo {
  icon_image_url: string;
  text: string;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_badge_list_image_size | null;
  small_image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_badge_list_small_image_size | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_metadata_emblem_badge_list_image_size | null;
  small_image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_metadata_emblem_badge_list_small_image_size | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_brand_name_badge_list_image_size | null;
  small_image_size: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_brand_name_badge_list_small_image_size | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list {
  type: ShopUxComponentType;
  product: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_fomo | null;
  managed_category_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_main_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_sub_title {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_check_button_item_list {
  id: string;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_sorting_item_list {
  id: string;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting {
  main_title: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_main_title | null;
  sub_title: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_sub_title | null;
  check_button_item_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_check_button_item_list[];
  sorting_item_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting_sorting_item_list[];
}

export interface GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list {
  /**
   * 전체 카테고리 목록
   */
  category_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_category_list[];
  /**
   * 자체제작 상품 모음. 상품 목록
   */
  item_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_item_list[];
  /**
   * check_button_item_list : 상품 필터 리스트 (직진 배송, 무료 배송)
   * sorting_item_list: 상품 정렬 필터 리스트 (신상순/ 인기순/ 등등)
   */
  filter_and_sorting: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list_filter_and_sorting | null;
  end_cursor: string | null;
  has_next: boolean;
  store_home_theme: string | null;
}

export interface GetSelfProductionProductCollectionList {
  /**
   * 자체제작 상품 전달
   */
  shop_ux_self_production_product_collection_list: GetSelfProductionProductCollectionList_shop_ux_self_production_product_collection_list;
}

export interface GetSelfProductionProductCollectionListVariables {
  input: ShopUxSelfProductionProductCollectionListInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopInfo
// ====================================================

export interface ShopInfo_style_list {
  /**
   * 값
   */
  value: string | null;
}

export interface ShopInfo_representative_coupon {
  /**
   * 이름
   */
  title: string;
  /**
   * 쿠폰 전체 목록 URL
   */
  link_url: string;
}

export interface ShopInfo {
  /**
   * 샵 아이디
   */
  id: string;
  /**
   * 샵 이름
   */
  name: string;
  /**
   * 샵 이미지
   */
  typical_image_url: string;
  /**
   * 연령대 목록
   */
  age_list: string[];
  /**
   * 스타일 목록
   */
  style_list: ShopInfo_style_list[];
  /**
   * 한줄문구
   */
  comment: string | null;
  /**
   * 샵 대표 쿠폰
   */
  representative_coupon: ShopInfo_representative_coupon | null;
  /**
   * 즐겨찾기 수
   */
  bookmark_count: number;
  /**
   * 브랜드홈 brand_id
   */
  brand_home_brand_id: string | null;
  /**
   * 상단 검색 버튼 및 검색관련 컴포넌트 노출 여부
   */
  show_search_button: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BannerGroup
// ====================================================

export interface BannerGroup_banner_list_link_button {
  type: ShopUxComponentType;
  /**
   * 텍스트
   */
  text: string;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface BannerGroup_banner_list {
  id: string;
  /**
   * webp image url
   */
  image_url: string | null;
  /**
   * 링크 종류
   */
  link_type: ShopUxBannerLinkType | null;
  /**
   * 배너 링크 이동 버튼 컴포넌트
   */
  link_button: BannerGroup_banner_list_link_button | null;
  /**
   * 배너 타이틀
   */
  title: string | null;
  /**
   * 관리를 위한 배너이름
   */
  name: string | null;
}

export interface BannerGroup {
  /**
   * 오토스크롤 간격(초)
   */
  auto_rolling_interval: number | null;
  /**
   * 오토스크롤 여부
   */
  is_auto_rolling: boolean;
  banner_list: BannerGroup_banner_list[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopInformation
// ====================================================

export interface ShopInformation_style_list {
  /**
   * 값
   */
  value: string | null;
}

export interface ShopInformation_sns_list_item_list {
  name: string;
  image_url: string;
  link_url: string;
}

export interface ShopInformation_sns_list {
  item_list: ShopInformation_sns_list_item_list[];
}

export interface ShopInformation_model_list_item_list {
  /**
   * 식별자
   */
  id: string;
  /**
   * 모델 대표 이미지
   */
  representative_image: string | null;
  /**
   * 모델 이름
   */
  name: string;
  /**
   * 키
   */
  height: number;
  /**
   * 몸무게
   */
  weight: string;
  /**
   * 상의 사이즈
   */
  top: string | null;
  /**
   * 하의 사이즈
   */
  bottom: string | null;
  /**
   * 발 사이즈
   */
  foot: string | null;
  /**
   * 가슴 사이즈
   */
  bust: string | null;
  /**
   * 허리 사이즈
   */
  waist: string | null;
  /**
   * 힙 사이즈
   */
  hip: string | null;
}

export interface ShopInformation_model_list {
  total_count: number;
  item_list: ShopInformation_model_list_item_list[];
}

export interface ShopInformation_title {
  text: string;
}

export interface ShopInformation_subtitle {
  text: string;
}

export interface ShopInformation {
  /**
   * 샵 아이디
   */
  id: string;
  /**
   * 샵 이름
   */
  name: string;
  /**
   * 샵 메인도메인
   */
  main_domain: string;
  /**
   * 한줄문구
   */
  comment: string | null;
  /**
   * 샵 도메인
   */
  url: string;
  /**
   * 스토어 바로가기 여부
   */
  has_store_shortcut: boolean;
  /**
   * 스타일 목록
   */
  style_list: ShopInformation_style_list[];
  /**
   * 스토어 SNS 계정 목록
   */
  sns_list: ShopInformation_sns_list;
  /**
   * 스토어 모델 목록
   */
  model_list: ShopInformation_model_list;
  /**
   * 셀렉티드 스토어홈 변경사항 (NORMAL/ SELECTED)
   */
  store_home_theme: string | null;
  /**
   * 즐겨찾기 수
   */
  bookmark_count: number;
  /**
   * 즐찾 여부
   */
  is_saved_shop: boolean;
  /**
   * 즐겨찾기 수 노출 여부
   */
  is_bookmark_count_displayed: boolean | null;
  /**
   * 셀렉티드 스토어홈 한글 이름
   */
  title: ShopInformation_title;
  subtitle: ShopInformation_subtitle;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxText
// ====================================================

export interface ShopUxText {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 텍스트 스타일
   */
  style: ShopUxTextStyle | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxFilterItem
// ====================================================

export interface ShopUxFilterItem {
  id: string;
  name: string | null;
  description: string | null;
  image_url: string | null;
  selected: boolean | null;
  disabled: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxImage
// ====================================================

export interface ShopUxImage {
  image_url: string;
  aspect_Ratio: number | null;
  /**
   * 이미지 클릭시 랜딩될 딥링크
   */
  link_url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxButton
// ====================================================

export interface ShopUxButton {
  /**
   * 텍스트
   */
  text: string;
  /**
   * HTML 텍스트 여부
   */
  is_html_text: boolean | null;
  /**
   * 버튼 스타일
   */
  style: ShopUxButtonStyle | null;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxProductCardFomo
// ====================================================

export interface ShopUxProductCardFomo {
  icon_image_url: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxBadge
// ====================================================

export interface ShopUxBadge_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxBadge_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxBadge {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxBadge_image_size | null;
  small_image_size: ShopUxBadge_small_image_size | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UxDisplayBadge
// ====================================================

export interface UxDisplayBadge_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxDisplayBadge_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxDisplayBadge {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: UxDisplayBadge_image_size | null;
  small_image_size: UxDisplayBadge_small_image_size | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxProductCardItem
// ====================================================

export interface ShopUxProductCardItem_product {
  shop_product_no: string | null;
  catalog_product_id: string | null;
  shop_id: number;
  url: string;
  image_url: string;
  webp_image_url: string | null;
  name: string;
  price: number;
  discount_rate: number | null;
  payment_type: ShopUxPaymentType | null;
  shipping_fee_type: ShopUxShippingFeeType | null;
  sales_status: ShopUxSalesStatus | null;
}

export interface ShopUxProductCardItem_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface ShopUxProductCardItem_fomo {
  icon_image_url: string;
  text: string;
}

export interface ShopUxProductCardItem_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
  /**
   * 카테고리 키
   */
  key: string;
}

export interface ShopUxProductCardItem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxProductCardItem_badge_list_image_size | null;
  small_image_size: ShopUxProductCardItem_badge_list_small_image_size | null;
}

export interface ShopUxProductCardItem_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxProductCardItem_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: ShopUxProductCardItem_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface ShopUxProductCardItem_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxProductCardItem_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: ShopUxProductCardItem_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface ShopUxProductCardItem_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxProductCardItem_metadata_emblem_badge_list_image_size | null;
  small_image_size: ShopUxProductCardItem_metadata_emblem_badge_list_small_image_size | null;
}

export interface ShopUxProductCardItem_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface ShopUxProductCardItem_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: ShopUxProductCardItem_brand_name_badge_list_image_size | null;
  small_image_size: ShopUxProductCardItem_brand_name_badge_list_small_image_size | null;
}

export interface ShopUxProductCardItem {
  product: ShopUxProductCardItem_product;
  /**
   * 스토어 명 (있을시에만 노출)
   */
  shop_name: string | null;
  browsing_type: ShopUxBrowsingType;
  /**
   * Z결제 단독 할인 적용 여부
   */
  is_zpay_discount: boolean;
  discount_info: ShopUxProductCardItem_discount_info | null;
  /**
   * Z결제 할인과 쿠폰 할인이 적용된 최종 금액
   */
  final_price: number;
  /**
   * 할인율을 극대화하기 위해 설정된 최대금액
   */
  max_price: number;
  /**
   * Z결제 미지원 여부
   */
  is_display_not_zpay: boolean;
  /**
   * 순위
   */
  ranking: number | null;
  /**
   * 한행에 노출되는 상품카드 개수
   */
  column_count: number | null;
  /**
   * 리뷰 개수
   */
  review_count: string | null;
  /**
   * 리뷰 평점
   */
  review_score: number | null;
  /**
   * FOMO
   */
  fomo: ShopUxProductCardItem_fomo | null;
  managed_category_list: ShopUxProductCardItem_managed_category_list[] | null;
  /**
   * 뱃지 목록
   */
  badge_list: ShopUxProductCardItem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: ShopUxProductCardItem_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: ShopUxProductCardItem_thumbnail_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트
   */
  metadata_emblem_badge_list: ShopUxProductCardItem_metadata_emblem_badge_list[] | null;
  /**
   * PLP 상품카드 스토어명 우측 영역 뱃지 리스트
   */
  brand_name_badge_list: ShopUxProductCardItem_brand_name_badge_list[] | null;
  /**
   * 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요)
   */
  is_plp_v2: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UxGoodsCardFomo
// ====================================================

export interface UxGoodsCardFomo {
  icon_image_url: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UxGoodsCardItem
// ====================================================

export interface UxGoodsCardItem_fomo {
  icon_image_url: string;
  text: string;
}

export interface UxGoodsCardItem_shop {
  id: string;
  name: string;
  main_domain: string;
  state: number;
  zpay_status: number | null;
}

export interface UxGoodsCardItem_discount_info {
  image_url: string | null;
  color: string | null;
  title: string | null;
}

export interface UxGoodsCardItem_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
  /**
   * 카테고리 키
   */
  key: string;
  /**
   * 카테고리 1차 또는 2차 또는 3차
   */
  depth: number;
}

export interface UxGoodsCardItem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: UxGoodsCardItem_badge_list_image_size | null;
  small_image_size: UxGoodsCardItem_badge_list_small_image_size | null;
}

export interface UxGoodsCardItem_thumbnail_nudge_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_thumbnail_nudge_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: UxGoodsCardItem_thumbnail_nudge_badge_list_image_size | null;
  small_image_size: UxGoodsCardItem_thumbnail_nudge_badge_list_small_image_size | null;
}

export interface UxGoodsCardItem_thumbnail_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_thumbnail_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: UxGoodsCardItem_thumbnail_emblem_badge_list_image_size | null;
  small_image_size: UxGoodsCardItem_thumbnail_emblem_badge_list_small_image_size | null;
}

export interface UxGoodsCardItem_metadata_emblem_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_metadata_emblem_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_metadata_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: UxGoodsCardItem_metadata_emblem_badge_list_image_size | null;
  small_image_size: UxGoodsCardItem_metadata_emblem_badge_list_small_image_size | null;
}

export interface UxGoodsCardItem_brand_name_badge_list_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_brand_name_badge_list_small_image_size {
  /**
   * 이미지 너비 크기
   */
  width: number;
  /**
   * 이미지 높이 크기
   */
  height: number;
}

export interface UxGoodsCardItem_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
  image_size: UxGoodsCardItem_brand_name_badge_list_image_size | null;
  small_image_size: UxGoodsCardItem_brand_name_badge_list_small_image_size | null;
}

export interface UxGoodsCardItem {
  goods_id: string | null;
  image_url: string | null;
  webp_image_url: string | null;
  video_url: string | null;
  uuid: string | null;
  bridge: boolean | null;
  product_url: string | null;
  title: string | null;
  price: number | null;
  discount_rate: number | null;
  free_shipping: boolean | null;
  origin_id: string | null;
  zpay: boolean | null;
  column_count: number | null;
  ranking: number | null;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  catalog_product_id: string | null;
  browsing_type: UxBrowsingType;
  aid: string | null;
  image_ratio: number | null;
  review_count: number | null;
  shop_product_no: string | null;
  shop_id: string;
  shop_name: string | null;
  zpay_price: number | null;
  similar_search: boolean | null;
  is_zonly: boolean;
  fomo: UxGoodsCardItem_fomo | null;
  shop: UxGoodsCardItem_shop | null;
  discount_info: UxGoodsCardItem_discount_info | null;
  /**
   * 북마크 여부
   */
  bookmarked: boolean;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: UxGoodsCardItem_managed_category_list[] | null;
  badge_list: UxGoodsCardItem_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: UxGoodsCardItem_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: UxGoodsCardItem_thumbnail_emblem_badge_list[] | null;
  /**
   * 가로형 상품카드 메타데이터 영역용 엠블렘
   */
  metadata_emblem_badge_list: UxGoodsCardItem_metadata_emblem_badge_list[] | null;
  brand_name_badge_list: UxGoodsCardItem_brand_name_badge_list[] | null;
  /**
   * 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함)
   */
  is_plp_v2: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopCategory
// ====================================================

export interface ShopCategory_children {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 부모 id
   */
  parent_id: string | null;
  /**
   * 동일 depth에서의 순서
   */
  sibling_order: number;
  /**
   * 카테고리 선택유무
   */
  selected: boolean | null;
}

export interface ShopCategory {
  /**
   * id
   */
  id: string;
  /**
   * 이름
   */
  name: string;
  /**
   * 부모 id
   */
  parent_id: string | null;
  /**
   * 동일 depth에서의 순서
   */
  sibling_order: number;
  /**
   * 자식 카테고리 목록
   */
  children: ShopCategory_children[];
  /**
   * 카테고리 선택유무
   */
  selected: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShopUxChipTab
// ====================================================

export interface ShopUxChipTab {
  id: string;
  name: string | null;
  selected: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ShopUxBannerLinkType {
  CATEGORY = "CATEGORY",
  COLLECTION = "COLLECTION",
  IMAGE = "IMAGE",
  PRODUCT = "PRODUCT",
  PRODUCT_GROUP = "PRODUCT_GROUP",
}

export enum ShopUxBrowsingType {
  DETAIL_BROWSER = "DETAIL_BROWSER",
  INTERNAL_BROWSER = "INTERNAL_BROWSER",
  NATIVE_BROWSER = "NATIVE_BROWSER",
  NORMAL = "NORMAL",
  Z_BROWSER = "Z_BROWSER",
}

/**
 * UxButton 스타일
 */
export enum ShopUxButtonStyle {
  BUTTON_DEFAULT = "BUTTON_DEFAULT",
  BUTTON_DEFAULT_NUM = "BUTTON_DEFAULT_NUM",
  BUTTON_SMALL = "BUTTON_SMALL",
  BUTTON_SMALL_NUM = "BUTTON_SMALL_NUM",
  DEFAULT_L = "DEFAULT_L",
  DEFAULT_M = "DEFAULT_M",
  DEFAULT_S = "DEFAULT_S",
  EXTENDED_TEXT_S_ARROW = "EXTENDED_TEXT_S_ARROW",
  PRIMARY_L = "PRIMARY_L",
  PRIMARY_M = "PRIMARY_M",
  PRIMARY_S = "PRIMARY_S",
  SECONDARY_L = "SECONDARY_L",
  SECONDARY_M = "SECONDARY_M",
  SECONDARY_S = "SECONDARY_S",
  SQUARE_DEFAULT_L = "SQUARE_DEFAULT_L",
  SQUARE_SECONDARY_L = "SQUARE_SECONDARY_L",
  TOGGLE_M = "TOGGLE_M",
  TOGGLE_S = "TOGGLE_S",
}

/**
 * Component 정의에 따라 변경예정
 */
export enum ShopUxComponentType {
  BUTTON = "BUTTON",
  CAROUSEL = "CAROUSEL",
  CHIP_AND_SORT = "CHIP_AND_SORT",
  ENTRY_BANNER = "ENTRY_BANNER",
  FULL_LOOKBOOK = "FULL_LOOKBOOK",
  IMAGE = "IMAGE",
  IMAGE_BANNER_AND_LIST = "IMAGE_BANNER_AND_LIST",
  IMAGE_CARD = "IMAGE_CARD",
  LINE_WITH_MARGIN = "LINE_WITH_MARGIN",
  LOOKBOOK = "LOOKBOOK",
  PRODUCT = "PRODUCT",
  PRODUCT_GROUP = "PRODUCT_GROUP",
  SHORT_FORM_CONTENT_LIST = "SHORT_FORM_CONTENT_LIST",
  TEXT = "TEXT",
  TEXT_AND_CHIP_TAB = "TEXT_AND_CHIP_TAB",
  TEXT_AND_SORT = "TEXT_AND_SORT",
  TEXT_TITLE = "TEXT_TITLE",
}

/**
 * 결제 방식
 */
export enum ShopUxPaymentType {
  EXTERNAL = "EXTERNAL",
  ZPAY = "ZPAY",
}

/**
 * 판매상태
 */
export enum ShopUxSalesStatus {
  CLOSED = "CLOSED",
  ON_SALE = "ON_SALE",
  PREPARING = "PREPARING",
  SOLD_OUT = "SOLD_OUT",
  SUSPENDED = "SUSPENDED",
}

/**
 * 배송비 종류
 */
export enum ShopUxShippingFeeType {
  FREE_SHIPPING = "FREE_SHIPPING",
  SHOP_POLICY = "SHOP_POLICY",
}

/**
 * UxText 스타일
 */
export enum ShopUxTextStyle {
  BODY_1 = "BODY_1",
  BODY_2 = "BODY_2",
  BODY_3 = "BODY_3",
  CAPTION = "CAPTION",
  CAPTION_NUM = "CAPTION_NUM",
  SUB_TITLE_1 = "SUB_TITLE_1",
  SUB_TITLE_2 = "SUB_TITLE_2",
  SUB_TITLE_3 = "SUB_TITLE_3",
  TITLE_1 = "TITLE_1",
  TITLE_2 = "TITLE_2",
}

export enum UxBrowsingType {
  DETAIL_BROWSER = "DETAIL_BROWSER",
  INTERNAL_BROWSER = "INTERNAL_BROWSER",
  NATIVE_BROWSER = "NATIVE_BROWSER",
  NORMAL = "NORMAL",
  Z_BROWSER = "Z_BROWSER",
}

export enum UxComponentType {
  UX_AD_BANNER_CARD = "UX_AD_BANNER_CARD",
  UX_AD_DISPLAY_BANNER_GROUP = "UX_AD_DISPLAY_BANNER_GROUP",
  UX_AD_DISPLAY_CLP_BANNER = "UX_AD_DISPLAY_CLP_BANNER",
  UX_AD_DISPLAY_LARGE_BANNER = "UX_AD_DISPLAY_LARGE_BANNER",
  UX_AD_DISPLAY_MID_BANNER = "UX_AD_DISPLAY_MID_BANNER",
  UX_AD_DISPLAY_REVIEW_BANNER = "UX_AD_DISPLAY_REVIEW_BANNER",
  UX_AD_DISPLAY_REVIEW_GROUP = "UX_AD_DISPLAY_REVIEW_GROUP",
  UX_AD_DISPLAY_SMALL_BANNER = "UX_AD_DISPLAY_SMALL_BANNER",
  UX_AD_DISPLAY_X_LARGE_BANNER = "UX_AD_DISPLAY_X_LARGE_BANNER",
  UX_AD_DISPLAY_X_MID_BANNER = "UX_AD_DISPLAY_X_MID_BANNER",
  UX_BAND_BANNER = "UX_BAND_BANNER",
  UX_BANNER_ITEM = "UX_BANNER_ITEM",
  UX_BANNER_LIST = "UX_BANNER_LIST",
  UX_BENEFITS_CARD = "UX_BENEFITS_CARD",
  UX_BRAND_CHIP_PRODUCT_LIST_GROUP = "UX_BRAND_CHIP_PRODUCT_LIST_GROUP",
  UX_BRAND_RECOMMEND_GROUP = "UX_BRAND_RECOMMEND_GROUP",
  UX_BRAND_THEME_CONTENT = "UX_BRAND_THEME_CONTENT",
  UX_BUTTON = "UX_BUTTON",
  UX_CATEGORY = "UX_CATEGORY",
  UX_CHECKBUTTON_AND_SELECTION_AND_SORTING = "UX_CHECKBUTTON_AND_SELECTION_AND_SORTING",
  UX_CHECK_BUTTON_AND_SORTING = "UX_CHECK_BUTTON_AND_SORTING",
  UX_CHIP_BUTTON_AND_PRODUCT_LIST_GRID_GROUP = "UX_CHIP_BUTTON_AND_PRODUCT_LIST_GRID_GROUP",
  UX_CHIP_BUTTON_GRID_GROUP = "UX_CHIP_BUTTON_GRID_GROUP",
  UX_CLP_CATEGORY = "UX_CLP_CATEGORY",
  UX_CLP_SEGMENT_TAB = "UX_CLP_SEGMENT_TAB",
  UX_DDP_TEMPLATE = "UX_DDP_TEMPLATE",
  UX_ENTRY_BANNER = "UX_ENTRY_BANNER",
  UX_ERROR = "UX_ERROR",
  UX_FULL_WIDTH_GOODS_CARD_ITEM = "UX_FULL_WIDTH_GOODS_CARD_ITEM",
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = "UX_FULL_WIDTH_IMAGE_BANNER_GROUP",
  UX_GOODS_CARD_ITEM = "UX_GOODS_CARD_ITEM",
  UX_GOODS_CARD_LIST = "UX_GOODS_CARD_LIST",
  UX_GOODS_CAROUSEL = "UX_GOODS_CAROUSEL",
  UX_GOODS_FILTER_LIST = "UX_GOODS_FILTER_LIST",
  UX_GOODS_GRID_GROUP = "UX_GOODS_GRID_GROUP",
  UX_GOODS_GROUP = "UX_GOODS_GROUP",
  UX_HYBRID_PROMOTION = "UX_HYBRID_PROMOTION",
  UX_IMAGE = "UX_IMAGE",
  UX_IMAGE_AND_TEXT_TITLE = "UX_IMAGE_AND_TEXT_TITLE",
  UX_IMAGE_BANNER_GROUP = "UX_IMAGE_BANNER_GROUP",
  UX_IMAGE_GOODS_CARD_ITEM = "UX_IMAGE_GOODS_CARD_ITEM",
  UX_LINE = "UX_LINE",
  UX_LINE_WITH_MARGIN = "UX_LINE_WITH_MARGIN",
  UX_LOTTIE_BANNER_WITH_LANDING_BUTTON = "UX_LOTTIE_BANNER_WITH_LANDING_BUTTON",
  UX_MULTILINE_RANKING_CAROUSEL = "UX_MULTILINE_RANKING_CAROUSEL",
  UX_NO_RESULTS = "UX_NO_RESULTS",
  UX_PDP_RECOMMEND_GROUP = "UX_PDP_RECOMMEND_GROUP",
  UX_PRODUCT_LIST_GRID_GROUP = "UX_PRODUCT_LIST_GRID_GROUP",
  UX_PRODUCT_REVIEW_SUMMARY_GROUP = "UX_PRODUCT_REVIEW_SUMMARY_GROUP",
  UX_PROMOTION_FLOATING_BANNER = "UX_PROMOTION_FLOATING_BANNER",
  UX_PROMOTION_TC = "UX_PROMOTION_TC",
  UX_QUICK_MENU = "UX_QUICK_MENU",
  UX_SEARCHED_SHOP_CAROUSEL = "UX_SEARCHED_SHOP_CAROUSEL",
  UX_SEARCH_KEYWORD_GUIDE = "UX_SEARCH_KEYWORD_GUIDE",
  UX_SEARCH_RESULT_EXTRA_HEADER = "UX_SEARCH_RESULT_EXTRA_HEADER",
  UX_SEARCH_RESULT_HEADER = "UX_SEARCH_RESULT_HEADER",
  UX_SEARCH_RESULT_QUICK_FILTER_LIST = "UX_SEARCH_RESULT_QUICK_FILTER_LIST",
  UX_SEGMENT_TAB = "UX_SEGMENT_TAB",
  UX_SELECTION_AND_SORTING = "UX_SELECTION_AND_SORTING",
  UX_SHOP_EXPANDABLE_LIST = "UX_SHOP_EXPANDABLE_LIST",
  UX_SHOP_GROUP = "UX_SHOP_GROUP",
  UX_SHOP_RANKING_CARD_ITEM = "UX_SHOP_RANKING_CARD_ITEM",
  UX_SIMPLE_PROMOTION_BANNER = "UX_SIMPLE_PROMOTION_BANNER",
  UX_SINGLE_BANNER = "UX_SINGLE_BANNER",
  UX_SMALL_TEXT_TITLE = "UX_SMALL_TEXT_TITLE",
  UX_SORTING = "UX_SORTING",
  UX_TEXT = "UX_TEXT",
  UX_TEXT_AND_MORE_BUTTON = "UX_TEXT_AND_MORE_BUTTON",
  UX_TEXT_AND_SORTING = "UX_TEXT_AND_SORTING",
  UX_TEXT_TITLE = "UX_TEXT_TITLE",
  UX_THEME_CATEGORY = "UX_THEME_CATEGORY",
  UX_THEME_COMPONENT = "UX_THEME_COMPONENT",
  UX_THEME_COMPONENT_CAROUSEL_GROUP = "UX_THEME_COMPONENT_CAROUSEL_GROUP",
  UX_TIME_DEAL_CARD = "UX_TIME_DEAL_CARD",
  UX_WEB_PAGE = "UX_WEB_PAGE",
  UX_ZONLY_ENTRY_BANNER = "UX_ZONLY_ENTRY_BANNER",
  UX_ZONLY_ENTRY_CARD = "UX_ZONLY_ENTRY_CARD",
  UX_ZONLY_ENTRY_GROUP = "UX_ZONLY_ENTRY_GROUP",
  UX_ZONLY_ENTRY_INFO = "UX_ZONLY_ENTRY_INFO",
}

export enum UxPaymentCurrency {
  CAD = "CAD",
  JPY = "JPY",
  KRW = "KRW",
  USD = "USD",
}

export interface GoodsFilterOptionInput {
  title?: string | null;
  display_category_id_list?: string[] | null;
  properties?: SearchedGoodsPropertiesInput | null;
  attribute_list?: SearchedProductAttributeListInput[] | null;
  style_list?: SearchedProductStyleInput[] | null;
  price_range?: SearchedGoodsPriceRange | null;
  color_list?: string[] | null;
  is_selected_color_image_only?: boolean | null;
  purchase_age_list?: string[] | null;
  limit_count?: number | null;
  only_brand?: boolean | null;
  only_soho?: boolean | null;
  product_group_list?: string[] | null;
  model_size?: SearchedGoodsModelSizeInput | null;
  shop_id_list?: string[] | null;
  brand_id?: string | null;
}

export interface PriceWithCurrencyInput {
  currency: UxPaymentCurrency;
  decimal: number;
  price_without_decimal: number;
  display_currency: string;
  is_currency_prefix: boolean;
}

export interface SearchedGoodsModelHeightRange {
  gte?: number | null;
  lte?: number | null;
}

export interface SearchedGoodsModelSizeInput {
  height_range?: SearchedGoodsModelHeightRange | null;
}

export interface SearchedGoodsPriceRange {
  gte?: number | null;
  gte_with_currency?: PriceWithCurrencyInput | null;
  lte?: number | null;
  lte_with_currency?: PriceWithCurrencyInput | null;
}

export interface SearchedGoodsPropertiesInput {
  length_list?: string[] | null;
  fit_list?: string[] | null;
  material_list?: string[] | null;
  pattern_list?: string[] | null;
  detail_list?: string[] | null;
  sleeve_length_list?: string[] | null;
}

export interface SearchedProductAttributeListInput {
  key: string;
  name: string;
  value: string;
}

export interface SearchedProductStyleInput {
  name: string;
  value: string;
}

export interface ShopUxBestProductCollectionListInput {
  shop_id: string;
  category_id?: string | null;
  only_new: boolean;
  sorting_item_id?: string | null;
  chip_tab_item_id?: string | null;
  check_button_item_id_list?: string[] | null;
}

export interface ShopUxSelfProductionProductCollectionListInput {
  shop_id: string;
  category_id?: string | null;
  check_button_item_id_list?: string[] | null;
  sorting_id?: string | null;
  after?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
