import React from 'react';

import { Log } from '@common/log-manager';
import { UxGoodsCardItemForVerticalProductCard, VerticalProductCardGridContainer } from '@widgets/product-card';

import { useProductCardEvents } from '../../hooks/useProductCardEvents';

export interface StoreHomeVerticalProductCardListProps {
  item_list: UxGoodsCardItemForVerticalProductCard[];
  log?: Partial<Log>;
  use_impression?: boolean;
  columnCount?: 2 | 3;
  size?: 'small' | 'medium' | 'large';
}

export const StoreHomeVerticalProductCardList: React.FC<StoreHomeVerticalProductCardListProps> = (props) => {
  const { log, use_impression } = props;
  const { onClick, onImpression, onLikeClick } = useProductCardEvents();

  const handleProductReveal = (item: UxGoodsCardItemForVerticalProductCard, index: number) => {
    if (log != null && use_impression) {
      onImpression(item, index, log);
    }
  };

  return (
    <VerticalProductCardGridContainer
      columnCount={props.columnCount ?? 3}
      defaultColumnCount={props.columnCount ?? 3}
      defaultSize={props.size ?? 'small'}
      items={props.item_list}
      onImpression={handleProductReveal}
      onClick={(item, index, event) => onClick(event, item, index, log)}
      onLikeClick={(isLiked, item, index) => onLikeClick(isLiked, item, index, log)}
    />
  );
};
